import {
  Text,
  Stack,
  getTheme,
  Callout,
  mergeStyleSets,
  FontWeights,
} from "@fluentui/react";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { topMenuHeight } from "../../schema/Constants";
import { leftMenuIsExpandContext } from "../Layout/Layout";
import Account from "./Account";
import { UserMenu } from "./UserMenu";
import { useMsal } from "@azure/msal-react";
import { detailsUsersRBAC } from "../UsersRBCA/api";
import { MyAccountInfo } from "../UsersRBCA/models";
import { notification } from "web-analysis-lib";
import EditUserProfileDialog from "./EditUserProfileDialog";
import { useNavigate } from "react-router-dom";
import UpdateUserPhotoDialog from "./UpdateUserPhotoDialog";
import dalogLogo from "../../img/dalog-white-logo.svg";
import {
  LocalAccountInfo,
  IsClickedUpdateUserContext,
  IsClickedUpdateProfilePhotoContext,
  IsClickedUpdateUserNotificationsContext,
  IsClickedAccountIconContext,
} from "./context";

interface AccountInfoSimple {
  name: string;
  id: string;
}

const theme = getTheme();

const menuTopLeftId = "callout-menuTopLeft";

const textStyle: CSSProperties = {
  color: theme.palette.white,
  textAlign: "center",
  marginRight: "5em", // * Helps to center the title in the WebApp.
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    padding: "10px",
  },
  title: {
    marginBottom: 5,
    fontWeight: FontWeights.semilight,
  },
});

const containerStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  background: "var(--dalog-blue)",
  width: `100vw`,
  height: topMenuHeight,
  zIndex: 10,
  padding: "0px 20px 0px 20px",
};

export const TopMenu: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const context = useContext(leftMenuIsExpandContext);
  const [isCalloutUserVisible, setIsCalloutUserVisible] = useState(false);
  const [isModalUserUpdateVisible, setIsModalUserUpdateVisible] =
    useState(false);
  const navigate = useNavigate();
  const [
    isModalUserProfilePhotoUpdateVisible,
    setIsModalUserProfilePhotoUpdateVisible,
  ] = useState(false);
  const { accounts } = useMsal();
  const [myAccount, setMyAccount] = useState<MyAccountInfo>();

  // Gets the user details.
  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const aux: AccountInfoSimple = {
        name: account.name,
        id: account.localAccountId,
      };
      sessionStorage.setItem("accountInfoMin", JSON.stringify(aux));
      detailsUsersRBAC(account.localAccountId).then((resp) =>
        "properties" in resp
          ? resp.properties
            ? setMyAccount({
                ...resp.properties,
                pictureBase64: resp.pictureBase64,
              })
            : setMyAccount({
                email: "",
                firstName: "",
                lastName: "",
                memberId: account.localAccountId,
                pictureBase64: resp.pictureBase64,
              })
          : notification.warning("Issue catching current user data")
      );
    }
  }, [accounts]);

  useEffect(() => {
    if (myAccount) {
      sessionStorage.setItem("accountInfo", JSON.stringify(myAccount));
    }
  }, [myAccount]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        context?.setValue(false);
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [context]);

  const onSuccess = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = `Failed updating ${displayName}`;
      notification.error(message);
    } else {
      const message = `${displayName} updated successfully`;
      notification.success(message);
    }
  };

  return (
    <LocalAccountInfo.Provider value={{ val: myAccount, set: setMyAccount }}>
      {isCalloutUserVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={"callout-menuUser"}
          ariaDescribedBy={"callout-menuUser"}
          role="dialog"
          gapSpace={0}
          target={`#${menuTopLeftId}`}
          onDismiss={() => setIsCalloutUserVisible(false)}
          setInitialFocus
        >
          <IsClickedUpdateUserContext.Provider
            value={setIsModalUserUpdateVisible}
          >
            <IsClickedUpdateProfilePhotoContext.Provider
              value={setIsModalUserProfilePhotoUpdateVisible}
            >
              <IsClickedUpdateUserNotificationsContext.Provider
                value={(v) => {
                  navigate("/notifications");
                }}
              >
                <UserMenu handleDismiss={setIsCalloutUserVisible} />
              </IsClickedUpdateUserNotificationsContext.Provider>
            </IsClickedUpdateProfilePhotoContext.Provider>
          </IsClickedUpdateUserContext.Provider>
        </Callout>
      )}
      {isModalUserUpdateVisible && (
        <EditUserProfileDialog
          onSuccess={onSuccess}
          onClose={() => setIsModalUserUpdateVisible(false)}
        />
      )}
      {isModalUserProfilePhotoUpdateVisible && (
        <UpdateUserPhotoDialog
          onSuccess={onSuccess}
          onClose={() => setIsModalUserProfilePhotoUpdateVisible(false)}
        />
      )}
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        style={containerStyle}
      >
        <Stack.Item style={{ width: "7em" }}>
          <img src={dalogLogo} alt="DALOG logo" />
        </Stack.Item>
        <Text as="h1" variant="mediumPlus" style={textStyle}>
          DALOG Data Platform
        </Text>
        <IsClickedAccountIconContext.Provider value={setIsCalloutUserVisible}>
          <Account id={menuTopLeftId} />
        </IsClickedAccountIconContext.Provider>
      </Stack>
    </LocalAccountInfo.Provider>
  );
};
