import {
  Text,
  Breadcrumb,
  Stack,
  IconButton,
  StackItem,
  IStackTokens,
} from "@fluentui/react";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Table, notification } from "web-analysis-lib";
import { signalGet } from "./api";
import { ResponseSimplifiedSignalWithAlertLevel } from "./models";
import {
  listAsyncMachines,
  selectMachine,
  selectMachinesError,
  selectMachinesStatus,
} from "../../Machines/reducer";
import {
  getColumnsAlertLevelsOnDet,
  getColumnsSignal,
} from "../../Machines/MachineDetails/columnsHelper";
import { UpdateSignalCondition } from "../models";
import { ResponseSimplifiedSignal, Sensor } from "../../Machines/models";
import { AlertLevel } from "../../Machines/MachineDetails/AlertLevels/models";
import { alertLevelsList } from "../../Machines/MachineDetails/AlertLevels/api";
import { EditCondition } from "../EditCondition";
import { sensorsList } from "../../Sensors/api";
import { WirelessSensorNode } from "../../SensorNodes/models";
import { list } from "../../SensorNodes/api";
import { CUDDialog } from "../CUDDialog";
import { CUDDialog as CUDDialogALev } from "../../Machines/MachineDetails/AlertLevels/CUDDialog";
import { useAuthorization } from "../../../Hooks/authorization";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";

// Styles //
const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const stackTokens: IStackTokens = {
  childrenGap: 50,
};

export const SignalDetails: React.FunctionComponent = () => {
  const auth = useContext(authContext);
  const { machineId, id } = useParams();
  const [item, setItem] = useState<ResponseSimplifiedSignalWithAlertLevel>();
  const [sensors, setSensors] = useState<Sensor[]>();
  const [sensorNodes, setSensorNodes] = useState<WirelessSensorNode[]>();

  const [actionAlertLevel, setActionAlertLevel] = useState<{
    data: AlertLevel | string | undefined;
    context: "add" | "edit" | "delete";
  }>();

  const [actionSignals, setActionSignals] = useState<{
    data: ResponseSimplifiedSignal | string | undefined;
    context: "add" | "edit" | "delete";
  }>();

  const [actionSignalCondition, setActionSignalsCondition] = useState<{
    data: UpdateSignalCondition;
    context: "edit";
  }>();

  //const machines = useAppSelector(selectMachinesToList);
  const status = useAppSelector(selectMachinesStatus);
  const error = useAppSelector(selectMachinesError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    status === Status.void && dispatch(listAsyncMachines());
  }, [dispatch, status]);

  const machine = useAppSelector(selectMachine(machineId));
  const navigate = useNavigate();

  useEffect(() => {
    signalGet(machineId, id).then((res) =>
      "status" in res
        ? notification.error("Signal fetching went wrong.")
        : setItem(res)
    );
    sensorsList(machineId).then((res) =>
      "status" in res
        ? notification.error("Sensors fetching went wrong.")
        : setSensors(res)
    );
    list().then((resp) =>
      !("status" in resp)
        ? setSensorNodes(resp.filter((sens) => sens.machineId === machineId))
        : setSensorNodes([])
    );
  }, [machineId, id]);

  const content = (
    <Stack tokens={stackTokens} styles={{ root: { margin: "50px" } }}>
      <StackItem>
        <Text style={titleStylePivot}>Details:</Text>
        <Stack>
          <Table
            persistOpts={{
              key: "table-signalCondition",
              version: 2,
            }}
            header={{
              title: "Details",
            }}
            items={[item]}
            columns={getColumnsSignal({
              onEditCondition(signalId, condition) {
                setActionSignalsCondition({
                  data: { id: signalId, condition: condition },
                  context: "edit",
                });
              },
              onEdit(signal) {
                setActionSignals({
                  data: signal,
                  context: "edit",
                });
              },
              noLink: true,
              metaDataContributor: auth.metaDataContributor,
            })}
            hasSelection={false}
            isLoading={item === undefined}
            hidePerPage
            isError={status === Status.error}
          />
        </Stack>
      </StackItem>
      <StackItem>
        <Text style={titleStylePivot}>Alert Level :</Text>
        <Table
          persistOpts={{
            key: "table-alertLevel",
            version: 2,
          }}
          header={{
            title: "Alert Level",
          }}
          items={item?.alertLevel ? [item?.alertLevel] : []}
          columns={getColumnsAlertLevelsOnDet({
            hasActions: true,
            onEdit(alertLevel) {
              setActionAlertLevel({
                data: alertLevel,
                context: "edit",
              });
            },
            onDelete(alertLevelId) {
              setActionAlertLevel({
                data: alertLevelId,
                context: "delete",
              });
            },
          })}
          hasSelection={false}
          isLoading={item === undefined}
          hidePerPage
          isError={status === Status.error}
        />
      </StackItem>
    </Stack>
  );

  const goBack = () => navigate(-1);

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
        <Breadcrumb
          items={[
            {
              key: `corporation-${machine?.corporation?.number}`,
              text: machine?.corporation?.name,
            },
            {
              key: `company-${machine?.company?.number}`,
              text: machine?.company?.name,
            },
            {
              key: `project-${machine?.project?.name}`,
              text: machine?.project?.name,
            },
            { key: `machine-${machine?.dalogId}`, text: machine?.dalogId },
            { key: `signal-${item?.name}`, text: item?.name },
          ]}
          styles={{
            root: { margin: 0 },
            item: { fontSize: "14px" },
            chevron: { fontSize: "10px" },
          }}
        />
      </Stack>
      {content}
      {actionSignalCondition && (
        <EditCondition
          machineId={machineId}
          data={actionSignalCondition?.data}
          show={["edit"].includes(actionSignalCondition?.context)}
          onSuccess={(hasError) => {
            if (hasError) {
              const message = `Failed updating Signal condition`;

              notification.error(message);
            } else {
              signalGet(machineId, id).then((res) =>
                "status" in res
                  ? notification.error("Signal fetching went wrong.")
                  : setItem(res)
              );
              const message = `Signal condition updated successfully`;
              notification.success(message);
            }
          }}
          onClose={() => {
            setActionSignalsCondition(undefined);
          }}
        ></EditCondition>
      )}
      {actionSignals && (
        <CUDDialog
          machineId={machineId}
          data={actionSignals?.data}
          sensors={sensors}
          sensorNodes={sensorNodes}
          show={["add", "edit", "delete"].includes(actionSignals?.context)}
          onSuccess={(hasError, context) => {
            if (hasError) {
              const message = `Failed ${
                context === "add"
                  ? "creating"
                  : context === "edit"
                  ? "updating"
                  : "deleting (It may not be a custom signal.) "
              } Signal`;

              notification.error(message);
            } else {
              signalGet(machineId, id).then((res) =>
                "status" in res
                  ? notification.error("Signal fetching went wrong.")
                  : setItem(res)
              );
              const message = `Signal ${context}  successfully`;

              notification.success(message);
            }
          }}
          onClose={() => {
            setActionSignals(undefined);
          }}
          context={actionSignals?.context}
        />
      )}
      {actionAlertLevel && (
        <CUDDialogALev
          machineId={machineId}
          data={actionAlertLevel?.data}
          signals={[item]}
          show={["add", "edit", "delete"].includes(actionAlertLevel?.context)}
          onSuccess={(hasError, context) => {
            if (hasError) {
              const message = `Failed ${
                context === "add" ? "creating" : "updating"
              } Alert level`;

              notification.error(message);
            } else {
              alertLevelsList(id).then((response) => {
                "status" in response
                  ? notification.error("Alert levels fetching went wrong.")
                  : signalGet(machineId, id).then((res) =>
                      "status" in res
                        ? notification.error("Signal fetching went wrong.")
                        : setItem(res)
                    );
              });
              const message = `Alert level ${context}  successfully`;

              notification.success(message);
            }
          }}
          onClose={() => {
            setActionAlertLevel(undefined);
          }}
          context={actionAlertLevel?.context}
        />
      )}
    </>
  );
};
