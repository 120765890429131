import {
  IDialogProps,
  DialogType,
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  BaseDialog,
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "web-analysis-lib";
import {
  WirelessSensorNodeResponse,
  RequestWirelessSensorNodeAdd,
  WirelessSensorNode,
} from "./models";
import { useEffect, useState } from "react";
import { z } from "zod";
import { editWirelessSensorNode } from "./api";
import { maxLengthType1 } from "../../schema/Constants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectWirelessSensorNodes, listAsyncSensorNodes } from "./reducer";
import type { FieldError } from "react-hook-form";
import { areObjectsEqual } from "../../schema/Utils";

/**
 * Edit Sensor Dialog Props type
 * */
type EditSensorDialogProps = IDialogProps & {
  data: WirelessSensorNodeResponse | null;
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: WirelessSensorNodeResponse | RequestWirelessSensorNodeAdd,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

/**
 * Gets the validation Zod schema
 * @param sensorNodes The sensor nodes reference list for validation
 * @returns the Zod object.
 */
const getFormSchema = (sensorNodes: WirelessSensorNodeResponse[]) =>
  z
    .object({
      id: z.string().optional(),
      sensorNodeId: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Sensor node Id must contain at most ${maxLengthType1} character(s)`,
        }),
      deviceModel: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Device Model must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
      deviceClass: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Device Class must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
    })
    .refine(
      (input) => {
        let newSensorNodeId = input?.sensorNodeId?.trim().toLowerCase();
        if (!newSensorNodeId) {
          return true;
        }

        return (
          sensorNodes.findIndex(
            (value) =>
              value.sensorNodeId.trim().toLowerCase() === newSensorNodeId &&
              value.id !== input.id
          ) === -1
        );
      },
      {
        path: ["sensorNodeId"],
        message: "The Sensor Node Id already exists",
      }
    );

/**
 * Gets the form fields
 * @param control the form control
 * @param errors the field errors
 * @returns the form fields.
 */
const getFormFields = ({ control, errors }: any) => {
  const formProps: FormItemProps[] = [
    {
      name: "sensorNodeId",
      type: FormItemType.TextField,
      groupProps: { label: "Sensor Node Id / Sensor Serial No *" },
    },
    {
      name: "deviceModel",
      type: FormItemType.TextField,
      groupProps: { label: "Device Model *" },
    },
    {
      name: "deviceClass",
      type: FormItemType.TextField,
      groupProps: { label: "Device Class *" },
    },
  ];

  return renderFormItems(formProps, {
    control,
    errors: errors,
  });
};

/**
 * EditDialog component
 * @param data The sensor node response
 * @param show True to display the component. Otherwise false.
 * @param onSuccess Function that will be called when the form has been successfully submitted.
 * @param onClose Function that will be called when the component is closing.
 * @returns the EditDialog component.
 */
export const EditDialog = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditSensorDialogProps) => {
  // Hooks
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const sensorNodes = useAppSelector(selectWirelessSensorNodes);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: getFormSchema(sensorNodes),
    ...(!!data && {
      defaultValues: {
        id: data.id,
        sensorNodeId: data.sensorNodeId,
        deviceModel: data.deviceModel?.toString(),
        deviceClass: data.deviceClass?.toString(),
      },
    }),
  });

  // Gets the sensor nodes list.
  useEffect(() => {
    dispatch(listAsyncSensorNodes({ wireless: [], machs: [] }));
  }, [dispatch]);

  // Checks for a form values change.
  useEffect(() => {
    setIsFormChanged(
      !areObjectsEqual(control._formValues, control._defaultValues)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  // Event handlers
  const handleClose = () => {
    setLoading(false);
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    const newData: WirelessSensorNode = {
      ...data,
      deviceClass: Number(formData.deviceClass),
      deviceModel: Number(formData.deviceModel),
      sensorNodeId: formData.sensorNodeId,
    };

    // Sends the request to update the wireless sensor node.
    setLoading(true);
    await editWirelessSensorNode(newData).then((response) => {
      let hasError = "status" in response;
      onSuccess(hasError, newData, "edit");
      if (!hasError) {
        handleClose();
      }
    });
  });

  // Gets the form fields
  const formFields = getFormFields({
    control: control,
    errors: errors as { [schemaProp: string]: FieldError },
  });

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Edit sensor Node",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        {formFields.map((field) => field)}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save Changes"
            disabled={isLoading || !isValid || !isFormChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
