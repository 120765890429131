import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { axiosInstance } from "..";
import { useWebAnalysisPermissions } from "web-analysis-lib";

export interface IAuthorizationResult {
  userAdministrator: boolean;
  metaDataReader: boolean;
  metaDataContributor: boolean;
  auditReader: boolean;
  measuredDataReader: boolean;
  measuredDataContributor: boolean;
  machineCvReader: boolean;
  machineCvContributor: boolean;
  machineCvAdministrator: boolean;
  powerBiReader: boolean;
  powerBiContributor: boolean;
  imageContributor: boolean;
  imageAdministrator: boolean;
  wirelessSensorReader: boolean;
  wirelessSensorContributor: boolean;
  vpnReader: boolean;
  vpnContributor: boolean;
  submissionContributor: boolean;
  submissionAdministrator: boolean;
  accessToken: string;
  loading: boolean;
}

interface AzureAdToken {
  aio?: string;
  aud?: string[] | string;
  azp?: string;
  azpacr: string;
  exp?: number;
  iat?: number;
  idp?: string;
  iss?: string;
  name?: string;
  nbf?: number;
  oid?: string;
  preferred_username?: string;
  rh?: string;
  roles?: string[];
  scp?: string;
  sub?: string;
  tid?: string;
  uti?: string;
  ver?: string;
}

export function useAuthorization() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { setPermissions } = useWebAnalysisPermissions();

  const defaultAppPermissions = {
    userAdministrator: false,
    metaDataReader: false,
    metaDataContributor: false,
    auditReader: false,
    measuredDataReader: false,
    measuredDataContributor: false,
    machineCvReader: false,
    machineCvContributor: false,
    machineCvAdministrator: false,
    powerBiReader: false,
    powerBiContributor: false,
    imageContributor: false,
    imageAdministrator: false,
    wirelessSensorReader: false,
    wirelessSensorContributor: false,
    vpnReader: false,
    vpnContributor: false,
    submissionContributor: false,
    submissionAdministrator: false,
    loading: true,
    accessToken: "",
  };

  const [status, setStatus] = useState<IAuthorizationResult>({
    ...defaultAppPermissions,
  });

  const auth = () => {
    setStatus({ ...status, loading: true });
    setPermissions({ data: undefined, loading: true });

    instance
      .acquireTokenSilent({
        scopes: [process.env.REACT_APP_API_SCOPE!],
        account: account!,
        redirectUri: "http://localhost:3000/blank.html", // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
      })
      .then((t) => {
        const token = jwt_decode<AzureAdToken>(t.accessToken);
        const urlRequest = `/rbac/v1/users/${token.oid}`;
        axiosInstance
          .get<UserDetails>(urlRequest)
          .then((r) => {
            const permissions = r?.data?.permissions ?? [];
            setPermissions({ data: permissions, loading: false });

            const userAdministrator =
              permissions.filter((e) => e.role === "UserAdministrator").length >
              0;
            let metaDataReader =
              permissions.filter((e) => e.role === "MetaDataReader").length > 0;
            const metaDataContributor =
              permissions.filter((e) => e.role === "MetaDataContributor")
                .length > 0;
            if (metaDataContributor) {
              metaDataReader = true;
            }
            const auditReader =
              permissions.filter((e) => e.role === "AuditReader").length > 0;
            let measuredDataReader =
              permissions.filter((e) => e.role === "MeasuredDataReader")
                .length > 0;
            const measuredDataContributor =
              permissions.filter((e) => e.role === "MeasuredDataContributor")
                .length > 0;
            if (measuredDataContributor) {
              measuredDataReader = true;
            }
            let machineCvReader =
              permissions.filter((e) => e.role === "MachineCvReader").length >
              0;
            let machineCvContributor =
              permissions.filter((e) => e.role === "MachineCvContributor")
                .length > 0;

            const machineCvAdministrator =
              permissions.filter((e) => e.role === "MachineCvAdministrator")
                .length > 0;

            if (machineCvAdministrator) {
              machineCvContributor = true;
            }

            if (machineCvContributor) {
              machineCvReader = true;
            }

            let powerBiReader =
              permissions.filter((e) => e.role === "PowerBiReader").length > 0;
            const powerBiContributor =
              permissions.filter((e) => e.role === "PowerBiContributor")
                .length > 0;
            if (powerBiContributor) {
              powerBiReader = true;
            }
            const imageContributor =
              permissions.filter((e) => e.role === "ImageContributor").length >
              0;

            let imageAdministrator =
              permissions.filter((e) => e.role === "ImageAdministrator")
                .length > 0;

            let wirelessSensorReader =
              permissions.filter((e) => e.role === "WirelessSensorReader")
                .length > 0;
            const wirelessSensorContributor =
              permissions.filter((e) => e.role === "WirelessSensorContributor")
                .length > 0;
            if (wirelessSensorContributor) {
              wirelessSensorReader = true;
            }

            let vpnReader =
              permissions.findIndex((e) => e.role === "VpnReader") >= 0;
            const vpnContributor =
              permissions.findIndex((e) => e.role === "VpnContributor") >= 0;
            if (vpnContributor) {
              vpnReader = true;
            }

            let submissionContributor =
              permissions.findIndex(
                (e) => e.role === "SubmissionContributor"
              ) >= 0;
            const submissionAdministrator =
              permissions.findIndex(
                (e) => e.role === "SubmissionAdministrator"
              ) >= 0;
            if (submissionAdministrator) {
              submissionContributor = true;
            }

            setStatus({
              userAdministrator,
              metaDataReader,
              metaDataContributor,
              auditReader,
              measuredDataReader,
              measuredDataContributor,
              machineCvReader,
              machineCvContributor,
              machineCvAdministrator,
              powerBiReader,
              powerBiContributor,
              imageContributor,
              imageAdministrator,
              wirelessSensorReader,
              wirelessSensorContributor,
              vpnReader,
              vpnContributor,
              submissionContributor,
              submissionAdministrator,
              loading: false,
              accessToken: t.accessToken,
            });
          })
          .catch((e) => {
            console.error("Get User Details", e);
          });
      });
  };

  useEffect(() => {
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...status, auth };
}

interface UserDetails {
  permissions: PermissionExpanded[];
}

interface PermissionExpanded {
  id: string;
  role: string;
  scope: string;
  scopeResource: string;
  expiresAtUtc: Date;
}
