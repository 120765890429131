import { MemberType, RoleType, ScopeLevelType } from "../../schema/status";
import { ServiceAccountAddRole } from "../ServiceAccount/models";

// New Version // To Define later the rigth names to be consistent.

export interface EntityItemWithAccountRoles {
  issuer: string;
  displayName: string;
  email: string | null;
  memberId: string;
  type: string;
  corporation: {
    id: string;
    name: string;
  };
  company: {
    id: string;
    name: string;
  };
  project: {
    id: string;
    name: string;
  };
  machine: {
    id: string;
    name: string;
  };
  roles: string[];
}

export interface EntityItemWithAccountRolesToMapInTable {
  [key: string]: boolean | string | null;
  issuer: string;
  displayName: string;
  email: string | null;
  memberId: string;
  type: string;
  corporationId: string;
  corporationName: string;
  companyId: string;
  companyName: string;
  projectId: string;
  projectName: string;
  machineId: string;
  machineName: string;
}

export const roleMappings: Record<string, string> = {
  UserAdministrator: "userAdministrator",
  MetaDataReader: "metaDataReader",
  MetaDataContributor: "metaDataContributor",
  AuditReader: "auditReader",
  MeasuredDataReader: "measuredDataReader",
  MeasuredDataContributor: "measuredDataContributor",
  MachineCvReader: "machineCvReader",
  MachineCvContributor: "machineCvContributor",
  MachineCvAdministrator: "machineCvAdministrator",
  PowerBiReader: "powerBiReader",
  PowerBiContributor: "powerBiContributor",
  ImageContributor: "imageContributor",
  WirelessSensorReader: "wirelessSensorReader",
  WirelessSensorContributor: "wirelessSensorContributor",
};

/// To add //

export interface RequestRolesAddMany {
  memberAndOrServiceAccountIds: string[];
  roles: ServiceAccountAddRole[];
}

export interface RequestRolesAdd extends ServiceAccountAddRole {
  memberAndOrServiceAccountIds: string[];
}

/// Generic ///
export interface ResponseUserOrServiceAccount {
  userOrServiceAccountId: string;
  permissionId: string;
  displayName: string;
  expiresAtUtc?: string;
  memberType?: MemberType | undefined;
}

export interface ResponseScopeLevelMachine {
  scopeId: string;
  scopeResourceId: string;
  scopeDescription?: string;
  displayName: string;
  scopeLevelType?: ScopeLevelType | undefined;
  usersOrServiceAccounts: ResponseUserOrServiceAccount[];
}

export interface ResponseScopeLevelProject {
  scopeId: string;
  scopeResourceId: string;
  scopeDescription?: string;
  displayName: string;
  scopeLevelType?: ScopeLevelType | undefined;
  usersOrServiceAccounts: ResponseUserOrServiceAccount[];
  machines: ResponseScopeLevelMachine[];
}

export interface ResponseScopeLevelCompany {
  scopeId: string;
  scopeResourceId: string;
  scopeDescription?: string;
  displayName: string;
  scopeLevelType?: ScopeLevelType | undefined;
  usersOrServiceAccounts: ResponseUserOrServiceAccount[];
  projects: ResponseScopeLevelProject[];
}

export interface ResponseScopeLevelCorporation {
  scopeId: string;
  scopeResourceId: string;
  scopeDescription?: string;
  displayName: string;
  scopeLevelType?: ScopeLevelType | undefined;
  usersOrServiceAccounts: ResponseUserOrServiceAccount[];
  companies: ResponseScopeLevelCompany[];
}

export interface ResponseScopeLevelRoot {
  scopeId: string;
  scopeResourceId: string;
  scopeDescription?: string;
  displayName: string;
  scopeLevelType?: ScopeLevelType | undefined;
  usersOrServiceAccounts: ResponseUserOrServiceAccount[];
  corporations: ResponseScopeLevelCorporation[];
}

export interface ResponseRole {
  id: string;
  description: string;
  type: RoleType | undefined;
  rootScopeLevel: ResponseScopeLevelRoot;
}

export interface SimpleResponseRbacList {
  roleType: RoleType | undefined;
  scopeLevelType: ScopeLevelType | undefined;
  scopeResource: string;
  memberType: MemberType | undefined;
  memberId: string;
  memberName: string;
  permissionId: string;
  expiresAtUtc: string;
}

export interface ResponseRbacList {
  simpleItems: SimpleResponseRbacList[];
  expandedItems: ResponseRole[];
}
