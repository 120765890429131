import "../node_modules/web-analysis-lib/dist/style.css";
import "./index.css";
import ReactDOM from "react-dom";
import { App } from "./App";
import { mergeStyles } from "@fluentui/react";
import reportWebVitals from "./reportWebVitals";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import axios from "axios";
import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { ErrorState } from "./schema/reducer";
import { registerIcons } from "@fluentui/react/lib/Styling";
import Icon from "./Components/Icons/Icons";

function getUserFlow(): string {
  let result = "";
  const host = window.location.hostname.toLowerCase().split(".")[0];
  if (host !== "localhost") {
    const hostParts = host.split("-");
    if (hostParts.length > 1) {
      const lastElement = hostParts[hostParts.length - 1];
      if (
        lastElement !== "dev" &&
        lastElement !== "uat" &&
        lastElement !== "prd"
      ) {
        result = lastElement;
      }
    }
  }

  return result;
}

registerIcons({
  icons: {
    Home12: <Icon name="Home" />,
    Home12Blue: <Icon name="Home" color="#2C529F" />,
    TrendView: <Icon name="TrendView" />,
    RawView: <Icon name="RawView" />,
    Corporations: <Icon name="Corporations" />,
    Companies: <Icon name="Companies" />,
    Projects: <Icon name="Projects" />,
    Machines: <Icon name="Machines" />,
    Gateways: <Icon name="Gateways" />,
    SensorsNodes: <Icon name="SensorsNodes" />,
    CorporationsCoral: <Icon name="Corporations" color="coral" />,
    CompaniesCoral: <Icon name="Companies" color="coral" />,
    ProjectsCoral: <Icon name="Projects" color="coral" />,
    MachinesCoral: <Icon name="Machines" color="coral" />,
    GatewaysCoral: <Icon name="Gateways" color="coral" />,
    SensorsNodesCoral: <Icon name="SensorsNodes" color="coral" />,
    DashBoardsManagement: <Icon name="DashBoardsManagement" />,
    MachineCV: <Icon name="MachineCV" />,
    SensorConfiguration: <Icon name="SensorConfiguration" />,
    DashBoards: <Icon name="DashBoards" />,
    Users: <Icon name="Users" />,
    ListUsers: <Icon name="ListUsers" />,
    DeleteMe: <Icon name="Delete" />,
    Reveal: <Icon name="Reveal" />,
    UnReveal: <Icon name="UnReveal" />,
    NoImageIcon: <Icon name="NoImageIcon" />,
    Datalogger: <Icon name="Datalogger" />,
    Link: (
      <Icon name="Link" width="20" height="20" style={{ paddingTop: "3" }} />
    ),
    CloudUpgeared: <Icon name="CloudUpgeared" />,
    ApprovalSubmissions: (
      <Icon
        name="ApprovalSubmissions"
        width="24"
        height="24"
        style={{ padding: "1" }}
      />
    ),
    Images: <Icon name="Images" />,
    VpnIcon: <Icon width="17" height="17" name="VpnIcon" />,
  },
});

initializeIcons();

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100%",
  },
});

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_API_CLIENTID!,
    authority: getUserFlow()
      ? `${process.env.REACT_APP_API_AUTHORITY!}_${getUserFlow()}`
      : `${process.env.REACT_APP_API_AUTHORITY!}`,
    knownAuthorities: [process.env.REACT_APP_API_KNOWNAUTHORITIES],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  system: {
    loadFrameTimeout: 30 * 1000, // 30 seconds??? unclear if mill seconds or not
  },
};

const pca = new PublicClientApplication(configuration);

export async function acquireTokenSilent(): Promise<string> {
  const activeAccount = pca.getActiveAccount();
  const accounts = pca.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    throw new Error("User is not signed in!");
  }

  try {
    const authResult = await pca.acquireTokenSilent({
      scopes: [process.env.REACT_APP_API_SCOPE!],
      account: activeAccount || accounts[0],
      redirectUri: window.location.origin, // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout,
    });
    return authResult.accessToken;
  } catch (error) {
    console.error("MSAL", error);
    await pca.loginRedirect({
      scopes: [process.env.REACT_APP_API_SCOPE!],
      account: activeAccount || accounts[0],
      redirectUri: window.location.origin,
    });
  }
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL!,
  headers: { "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY! },
  timeout: 1000 * 60 * 10,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const accessToken = await acquireTokenSilent();

      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response ? error.response.status : null;
  const errorObj: ErrorState = {
    show: true,
    error: error.response.statusText,
    status: error.response.status,
    text: error.response.data,
  };

  if (statusCode === 401) {
    return errorObj;
  }

  if (statusCode === 403) {
    return errorObj;
  }

  if (statusCode === 404) {
    return errorObj;
  }

  if (statusCode >= 500) {
    return {
      show: true,
      error: "Oops, something is not right",
      status: "Error at request",
      text: "Please try again later.",
    };
  }

  if (statusCode === 400) {
    return errorObj;
  }

  return Promise.reject(error);
});

const AppProvider = () => (
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
