import { AxiosResponse } from "axios";
import { axiosInstance } from "../..";
import { UserExtendedProperties } from "../ServiceAccount/models";
import {
  ResponseUserIssuer,
  UserDetails,
  UserAdd,
  AzureAdb2CUserSignUp,
  UserPicture,
  UserUpdate,
} from "./models";

export async function listUsersRBAC(): Promise<ResponseUserIssuer[] | any> {
  const response = await axiosInstance.get<ResponseUserIssuer[] | any>(
    "/rbac/v1/users/"
  );
  return response.data;
}

export async function detailsUsersRBAC(id: string): Promise<UserDetails | any> {
  const response = await axiosInstance.get<UserDetails | any>(
    "/rbac/v1/users/" + id
  );
  if (response.data) return response.data;
  else return response;
}

export async function addUserRBAC(
  userAccountAdd: UserAdd
): Promise<UserExtendedProperties | any> {
  const config: UserAdd = userAccountAdd;
  const urlRequest = "/rbac/v1/users ";

  const response = await axiosInstance.post<any>(urlRequest, config);

  if (response.data) return response.data;
  else return response;
}

export async function addUserAzureADB2RBAC(
  userAccountAdd: AzureAdb2CUserSignUp
): Promise<any> {
  const config: AzureAdb2CUserSignUp = userAccountAdd;
  const urlRequest = "/rbac/v1/users/b2c ";

  const response = await axiosInstance.post<any>(urlRequest, config);

  if (response.data) return response.data;
  else return response;
}

export async function pictureGetUsersRBAC(
  id: string
): Promise<UserPicture | any> {
  const response = await axiosInstance.get<UserPicture | any>(
    "/rbac/v1/users/" + id + "/picture"
  );
  if (response.data) return response.data;
  else return response;
}

export async function pictureUpdateUsersRBAC(
  id: string,
  userPicture: UserPicture
): Promise<UserPicture | any> {
  const response = await axiosInstance.put<UserPicture | any>(
    "/rbac/v1/users/" + id + "/picture",
    userPicture
  );
  if (response.data) return response.data;
  else return response;
}

export const updateUserPhoto = async (
  data: UserPicture
): Promise<AxiosResponse<UserPicture, any>> => {
  let response: AxiosResponse<UserPicture, any> = {
    status: 0,
    statusText: "",
    data: null,
    headers: null,
    config: null,
  };

  try {
    response = await axiosInstance.put<UserPicture | any>(
      "/rbac/v1/users/" + data.id + "/picture",
      data
    );
  } catch (e: any) {
    response = { ...response, statusText: e?.message || "Unknown error" };
  }

  return response;
};

export async function deleteUserRBAC(id: string): Promise<any> {
  const urlRequest = "/rbac/v1/users/" + id;

  const response = await axiosInstance.delete<UserExtendedProperties | any>(
    urlRequest
  );

  if (response.data) return response.data;
  else return response;
}

export async function settingsUsersRBAC(): Promise<any> {
  const response = await axiosInstance.get<any>("/rbac/v1/users/");
  return response.data;
}

export async function settingsUpdateUsersRBAC(id: string): Promise<any> {
  const response = await axiosInstance.put<any>(
    "/rbac/v1/users/" + id + "/settings"
  );
  if (response.data) return response.data;
  else return response;
}

export async function updateUserRBAC(userUpdate: UserUpdate): Promise<any> {
  const response = await axiosInstance.put<any>(
    "/rbac/v1/users/" + userUpdate.memberId,
    userUpdate
  );
  if (response.data) return response.data;
  else return response;
}
