/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import {
  BaseDialog,
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
  notification,
} from "web-analysis-lib";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  IComboBoxOption,
  Text,
} from "@fluentui/react";
import { ZodType, z } from "zod";
import { maxLengthType1, titleStyle } from "../../schema/Constants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import {
  listAsyncCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";
import type { FieldError, Control, FieldErrors } from "react-hook-form";
import {
  RequestWirelessSensorNodeAdd,
  WirelessSensorNode,
  WirelessSensorNodeResponse,
} from "./models";
import { addWirelessSensorNode, editWirelessSensorNode } from "./api";
import { selectMachines } from "../Machines/reducer";
import { selectGatewaysProProjectOptions } from "../Gateways/reducer";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { areObjectsEqual } from "../../schema/Utils";

type AddOrEditDialogProps = IDialogProps & {
  options: IComboBoxOption[];
  data: WirelessSensorNodeResponse | null;
  items: WirelessSensorNodeResponse[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: WirelessSensorNodeResponse | RequestWirelessSensorNodeAdd,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

type WirelessAddOrEditDialogFormData = {
  sensorNodeId?: string;
  id?: string;
  deviceModel?: string;
  deviceClass?: string;
  sensorNo?: string;
  sensorDescription?: string;
  mountingType?: string;
  mountingDirection?: string;
};

const defaultUuid: string = "00000000-0000-0000-0000-000000000000";

const projectsFields: FormItemProps[] = [
  {
    name: "sensorNodeId",
    type: FormItemType.TextField,
    groupProps: { label: "Sensor Node Id / Sensor Serial No *" },
  },
  {
    name: "deviceModel",
    type: FormItemType.TextField,
    groupProps: { label: "Device Model *" },
  },
  {
    name: "deviceClass",
    type: FormItemType.TextField,
    groupProps: { label: "Device Class *" },
  },
  {
    name: "sensorNo",
    type: FormItemType.TextField,
    groupProps: { label: "Sensor No" },
  },
  {
    name: "sensorDescription",
    type: FormItemType.TextField,
    groupProps: { label: "Description" },
  },
  {
    name: "mountingType",
    type: FormItemType.TextField,
    groupProps: { label: "Mounting Type" },
  },
  {
    name: "mountingDirection",
    type: FormItemType.TextField,
    groupProps: { label: "Mounting Direction" },
  },
];

const getFiledToRender = (
  data: WirelessSensorNodeResponse,
  control: Control<WirelessAddOrEditDialogFormData, any>,
  errors: FieldErrors<WirelessSensorNodeResponse>
): JSX.Element[] => {
  return renderFormItems(data ? projectsFields.slice(0, 3) : projectsFields, {
    control,
    errors: errors as { [schemaProp: string]: FieldError },
  });
};

const getSchema = (sensorNodes: WirelessSensorNodeResponse[]) =>
  z
    .object({
      id: z.string().optional(),
      sensorNodeId: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Sensor node Id must contain at most ${maxLengthType1} character(s)`,
        }),
      deviceModel: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Device Model must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
      deviceClass: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Device Class must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
      sensorNo: z.string().optional().nullable(),
      sensorDescription: z.string().optional().nullable(),
      mountingType: z.string().optional().nullable(),
      mountingDirection: z.string().optional().nullable(),
    })
    .refine(
      (input) => {
        if (!input.sensorNodeId) {
          return true;
        }

        return (
          sensorNodes
            .map(({ sensorNodeId }) => sensorNodeId.trim().toLowerCase())
            .findIndex(
              (value) => value === input.sensorNodeId.trim().toLowerCase()
            ) === -1
        );
      },
      {
        path: ["sensorNodeId"],
        message: "The Sensor Node Id already exists",
      }
    );

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ sensorNodeId }) =>
        data
          ? sensorNodeId.trim().toLowerCase() !==
            data.sensorNodeId.trim().toLowerCase()
          : true
      ),
    [items, data]
  );
  const machines = useAppSelector(selectMachines);
  const schema = useMemo(() => getSchema(filteredItems), [filteredItems]);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [idGatewaySelected, setIdGatewaySelected] = useState<string>(
    data?.wirelessGatewayId || defaultUuid
  );
  const [idMachSelected, setIdMachSelected] = useState<string>(
    data?.machineId || defaultUuid
  );
  const [projectId, setProjectId] = useState<string>(data?.projectId);
  const optionsGateways = useAppSelector(
    selectGatewaysProProjectOptions(projectId)
  );
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useZodForm<ZodType<any, z.ZodTypeDef, WirelessAddOrEditDialogFormData>>({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        id: data.id ? data.id : " ",
      },
    }),
  });

  useEffect(() => {
    const mach = machines?.find((mac) => mac.id === idMachSelected);
    setProjectId(
      mach?.projectId && mach?.projectId !== defaultUuid
        ? mach?.projectId
        : undefined
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMachSelected]);

  useEffect(() => {
    optionsGateways?.some((gat) => gat.key === idGatewaySelected) &&
      setIdGatewaySelected(idGatewaySelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsGateways]);

  useEffect(() => {
    if (!projectId) {
      setIdGatewaySelected(data?.wirelessGatewayId || defaultUuid);
    } else if (projectId !== data?.projectId) {
      setIdGatewaySelected(data?.wirelessGatewayId || defaultUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data
      ? reset({
          id: data.id,
          sensorNodeId: data.sensorNodeId,
          deviceClass: data.deviceClass.toString(),
          deviceModel: data.deviceModel.toString(),
          sensorNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
        })
      : reset({
          id: "",
          deviceClass: "6000",
          sensorNodeId: "",
          deviceModel: "2111",
          sensorNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
        });
  }, [data, reset]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      areObjectsEqual(control._defaultValues, control._formValues) &&
      data?.machineId === idMachSelected &&
      data?.wirelessGatewayId === idGatewaySelected;
    setDataHasChanged(!areEqual);
  }, [watch(), idMachSelected, idGatewaySelected]);

  const onSubmit = handleSubmit(
    async (formData: WirelessAddOrEditDialogFormData) => {
      setLoading(true);

      if (data) {
        const toSend: WirelessSensorNode = {
          id: data.id,
          deviceClass: Number(formData.deviceClass),
          deviceModel: Number(formData.deviceModel),
          machineId: idMachSelected,
          sensorNodeId: formData.sensorNodeId,
        };

        if (idGatewaySelected) {
          toSend.wirelessGatewayId = idGatewaySelected;
        }

        await editWirelessSensorNode(toSend).then((response) =>
          onSuccess("status" in response, toSend, data ? "edit" : "add")
        );
      } else {
        const toSend: RequestWirelessSensorNodeAdd = {
          wirelessSensorNode: {
            deviceClass: Number(formData.deviceClass),
            deviceModel: Number(formData.deviceModel),
            machineId: idMachSelected,
            sensorNodeId: formData.sensorNodeId,
          },
          sensor: {
            machineId: idMachSelected,
            sensorSerialNo: formData.sensorNodeId,
            sensorNo: formData.sensorNo,
            sensorDescription: formData.sensorDescription,
            mountingType: formData.mountingType,
            mountingDirection: formData.mountingDirection,
          },
        };

        await addWirelessSensorNode(toSend).then((response) =>
          onSuccess("status" in response, toSend, data ? "edit" : "add")
        );
      }

      !idGatewaySelected && !idMachSelected
        ? notification.warning("Un-parented sensor node")
        : !idMachSelected &&
          notification.warning("Sensor node, non machine linked yet.");
      handleClose();
    }
  );

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? "Edit Sensor Node" : "Add Sensor Node",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        <FormItemRow label="Machine">
          <ControlledComboBox
            options={options}
            selectedKey={idMachSelected}
            disabled={false}
            onKeySelected={(key: string) => setIdMachSelected(key)}
          />
        </FormItemRow>
        {optionsGateways.length > 0 && (
          <FormItemRow label="Gateway">
            <ControlledComboBox
              options={optionsGateways}
              selectedKey={idGatewaySelected}
              disabled={false}
              onKeySelected={(key: string) => setIdGatewaySelected(key)}
            />
          </FormItemRow>
        )}
        {getFiledToRender(data, control, errors)
          .slice(0, 3)
          .map((ele) => ele)}
        {!data && <Text style={titleStyle}>Sensor:</Text>}
        {getFiledToRender(data, control, errors)
          .slice(3)
          .map((ele) => ele)}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save Changes"
            disabled={isLoading || !isValid || !dataHasChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
