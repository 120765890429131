/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useContext } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "web-analysis-lib";
import { IComboBoxOption, IDialogProps, Stack } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../../../schema/Constants";
import { RequestWirelessGatewayUpdateAux } from "../../models";
import FormItemRow from "../../../Generic/FormItemRow";
import ControlledComboBox from "../../../Generic/ControlledComboBox";
import { SupportContext } from "../SupportContext";

const getSchema = (serialNumbers: string[]) =>
  z
    .object({
      id: z.string().optional(),
      projectId: z.string().optional(),
      ltePlanSize: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      publicKey: z.string().optional(),
      rsshPort: z
        .union([
          z.string().length(0, { message: "Try a number between (1:65535)" }),
          z
            .string()
            .regex(
              /^(0|6[0-5][0-5][0-3][0-5]|[1-5][0-9][0-9][0-9][0-9]|[1-9][0-9]{0,3})$/
            ),
        ])
        .optional()
        .transform((e) => (e === "" ? "" : e)),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: "Try a number between (1.0:1000.0)",
      }
    );

const gatewayFields: FormItemProps[] = [
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: "Lte Plan Size *" },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: "Public Key" },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: "Rssh Port" },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi Password For AP" },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi SSID" },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: "IMEI" },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Sink Node Editress" },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Channel" },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Ethernet Mac Editress" },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: "Firmware" },
  },
];

type EditDialogProps = IDialogProps & {
  options: IComboBoxOption[];
  serialNumbers: string[];
  serialNumber: string;
};

export const EditDialog = ({
  options,
  serialNumbers,
  serialNumber,
  ...rest
}: EditDialogProps) => {
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support?.gatewaysSupportToUpdate.find(
    (gateway) => gateway.serialNumber === serialNumber
  );

  const schema = useMemo(
    () => getSchema(serialNumbers.filter((item) => item !== serialNumber)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      projectId: toUpdate?.projectId || "",
      id: toUpdate?.id || "",
      ltePlanSize: toUpdate?.ltePlanSize ? String(toUpdate.ltePlanSize) : "",
      publicKey: toUpdate?.publicKey || "",
      rsshPort: toUpdate?.rsshPort ? String(toUpdate.rsshPort) : "",
      wifiPasswordForAP: toUpdate?.wifiPasswordForAP || "",
      wifiSSID: toUpdate?.wifiSSID || "",
      iMEI: toUpdate?.iMEI || "",
      wirepassSinkNodeAddress: toUpdate?.wirepassSinkNodeAddress || "",
      wirepassChannel: toUpdate?.wirepassChannel || "",
      ethernetMacAddress: toUpdate?.ethernetMacAddress || "",
      firmware: toUpdate?.firmware || "",
    },
  });

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }
    const toUp: RequestWirelessGatewayUpdateAux = {
      ...toUpdate,
      matchUniqueId: toUpdate?.matchUniqueId,
      projectId: toUpdate?.projectId,
      ...(control._formValues as RequestWirelessGatewayUpdateAux),
      ltePlanSize: control._formValues.ltePlanSize
        ? Number(control._formValues.ltePlanSize)
        : undefined,
      rsshPort: control._formValues.rsshPort
        ? Number(control._formValues.rsshPort)
        : undefined,
      isValid,
    };

    isValid && updateSupportEle(toUp);
  }, [watch(), isValid]);

  return (
    <Stack>
      {
        <>
          <FormItemRow label="Current Project">
            <ControlledComboBox
              options={options}
              selectedKey={toUpdate?.projectId}
              disabled={true}
              onKeySelected={(key: string) => console.log("key")}
            />
          </FormItemRow>
          {renderFormItems(gatewayFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </>
      }
    </Stack>
  );
};
