import { useMemo, useEffect, useState, CSSProperties } from "react";
import {
  BaseDialog,
  useZodForm,
  renderFormItems,
  notification,
  DialogSize,
} from "web-analysis-lib";
import {
  DialogFooter,
  IDialogProps,
  DialogType,
  Stack,
  StackItem,
  Text,
  Spinner,
  SpinnerSize,
  PrimaryButton,
  Pivot,
  PivotItem,
  Persona,
  PersonaSize,
  TooltipHost,
  TextField,
} from "@fluentui/react";
import { Icon as IconF } from "@fluentui/react";
import { z } from "zod";
import { useAppSelector } from "../../hooks";
import { RoleType, ScopeLevelType, Status } from "../../schema/status";
import type { FieldError } from "react-hook-form";
import {
  UserFieldsNonReq,
  getUserFieldsReq,
  onPass,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { RolesComponentToAdd } from "../Roles/RolesComponentToAdd";
import { addUserRBAC } from "./api";
import { UserAdd } from "./models";
import {
  ServiceAccountAddRole,
  UserExtendedProperties,
} from "../ServiceAccount/models";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";
import { UpdateUserPhoto } from "./UpdateUserPhoto";
import {
  extract4Initials,
  getObjectUrlFromBase64,
  mapInitialsToNumber,
} from "../../schema/Utils";
import { ConfirmDialog } from "../Generic/ConfirmDialog";

export interface ServiceAccountAddRoleToShow extends ServiceAccountAddRole {
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  resource?: string;
}

export type personaProps = {
  persona: RoleType[];
  root: boolean;
};

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, { message: "This field is required" }),
    lastName: z.string().min(1, { message: "This field is required" }),
    firstName: z.string().min(1, { message: "This field is required" }),
    jobTitle: z.string().optional(),
    companyName: z.string().optional(),
    department: z.string().optional(),
    streetAddress: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z.string().optional(),
    zipOrPostalCode: z.string().optional(),
    countryOrRegion: z.string().optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: "Invalid number" }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
  });

type AddDialogProps = IDialogProps & {
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const AddDialog = ({
  show,
  onSuccess,
  onClose,
  ...rest
}: AddDialogProps) => {
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);
  const [isLoading, setLoading] = useState(false);
  const [selectedPivotKey, setSelectedPivotKey] = useState("properties");
  const [formDataToConfirm, setFormDataToConfirm] = useState(null);
  const [pictureToAdd, setPictureToAdd] = useState();
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>(
    []
  );
  const [showAddPicture, setShowAddPicture] = useState(false);
  const [currentUserName, setCurrentUserName] = useState<string>();
  const [showUrlDialog, setShowUrlDialog] = useState({
    show: false,
    url: undefined,
  });

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId
      )
    );
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...{
      defaultValues: {
        email: undefined,
        lastName: undefined,
        firstName: undefined,
        jobTitle: undefined,
        companyName: undefined,
        department: undefined,
        streetAddress: undefined,
        city: undefined,
        stateOrProvince: undefined,
        zipOrPostalCode: undefined,
        countryOrRegion: undefined,
        mobilePhone: undefined,
        memberId: undefined,
      },
    },
  });
  useEffect(() => {
    const first = control._formValues.firstName
      ? control._formValues.firstName
      : "";
    const second = control._formValues.lastName
      ? control._formValues.lastName
      : "";
    setCurrentUserName(first + " " + second);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const handleClose = () => {
    setLoading(false);
    setPictureToAdd(undefined);
    setShowUrlDialog({ show: false, url: undefined });
    onClose?.();
  };

  const submitForm = async (formData: any) => {
    if (!formData) {
      return;
    }

    const toSubmit: UserAdd = {
      ...(formData as UserAdd),
      pictureBase64: pictureToAdd ? pictureToAdd : undefined,
      roles: rolesToAdd as ServiceAccountAddRole[],
    };
    await addUserRBAC(toSubmit).then((res: UserExtendedProperties) => {
      onSuccess(status in res, toSubmit.firstName);
      setShowUrlDialog({ show: true, url: res.inviteUrl });
    });
    setLoading(false);
    handleClose();
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    // Checks whether there are roles assigned to the user.
    if (rolesToAdd.length === 0) {
      setFormDataToConfirm(formData);
    } else {
      submitForm(formData);
    }
  });

  const handleLinkClick = (item?: PivotItem): void => {
    var selectedKey = item?.props?.itemKey?.toString();
    if (!selectedKey) {
      return;
    }

    setSelectedPivotKey(selectedKey);
  };

  return (
    <>
      <BaseDialog
        {...rest}
        hidden={!show}
        dialogContentProps={{
          type: DialogType.close,
          title: "Add User",
          closeButtonAriaLabel: "Close",
          onDismiss: handleClose,
        }}
        styles={{
          main: {
            "@media (min-width: 480px)": {
              height: "55vh",
            },
          },
        }}
        size={DialogSize.L}
      >
        <div style={pageStyle}>
          <Pivot
            aria-label="Pivots to add user"
            onLinkClick={handleLinkClick}
            selectedKey={selectedPivotKey}
          >
            <PivotItem
              headerText={"Properties"}
              itemKey={"properties"}
              onRenderItemLink={() => (
                <>
                  {isValid && (
                    <>
                      <IconF iconName="SkypeCheck" />
                    </>
                  )}

                  <Text style={titleStylePivot}>Properties</Text>
                </>
              )}
            >
              <Stack horizontalAlign="space-evenly" verticalAlign="center">
                <div style={{ marginTop: "50px" }}>
                  <Text style={titleStyle}>General</Text>
                </div>
                <Stack
                  style={{ maxWidth: "70vw", padding: "35px" }}
                  wrap
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  horizontalAlign="space-between"
                  verticalAlign="center"
                >
                  {renderFormItems(
                    [...getUserFieldsReq(), ...UserFieldsNonReq],
                    {
                      control,
                      errors: errors as { [schemaProp: string]: FieldError },
                    }
                  ).map((ele) => (
                    <StackItem key={ele.key}> {ele}</StackItem>
                  ))}
                </Stack>
              </Stack>
            </PivotItem>
            <PivotItem
              headerText={"Roles"}
              itemKey={"roles"}
              onRenderItemLink={() => (
                <>
                  {rolesToAdd.length > 0 && (
                    <>
                      <IconF iconName="SkypeCheck" />
                    </>
                  )}
                  <Text style={titleStylePivot}>Roles</Text>
                </>
              )}
            >
              {RolesComponentToAdd({
                rolesToAdd,
                setRolesToAdd,
                onDelete,
              })}
            </PivotItem>
            <PivotItem
              headerText={"Picture"}
              itemKey={"setPicture"}
              onRenderItemLink={() => (
                <>
                  {pictureToAdd && (
                    <>
                      <IconF iconName="SkypeCheck" />
                    </>
                  )}
                  <Text style={titleStylePivot}>Picture</Text>
                </>
              )}
            >
              <Stack
                horizontalAlign="center"
                style={{ padding: "20px" }}
                tokens={{ childrenGap: 20 }}
              >
                <StackItem style={{ padding: "20px" }}>
                  <Persona
                    imageInitials={
                      currentUserName
                        ? extract4Initials(currentUserName)
                        : undefined
                    }
                    initialsColor={mapInitialsToNumber(
                      extract4Initials(currentUserName ? currentUserName : "")
                    )}
                    size={PersonaSize.size120}
                    styles={{ root: { display: "block", padding: "20px" } }}
                    imageUrl={
                      pictureToAdd
                        ? getObjectUrlFromBase64(pictureToAdd)
                        : undefined
                    }
                  />
                </StackItem>
                <PrimaryButton
                  text={pictureToAdd ? "Update Picture" : "Add Picture"}
                  onClick={() => setShowAddPicture((prev) => !prev)}
                />
              </Stack>
            </PivotItem>
          </Pivot>
          <DialogFooter>
            <PrimaryButton
              text="Save Changes"
              disabled={isLoading || !isValid}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
              onClick={onSubmit}
            />
          </DialogFooter>
          <BaseDialog
            //{...rest}
            hidden={!showAddPicture}
            dialogContentProps={{
              title: "Add Picture",
              type: DialogType.close,
              onDismiss: () => setShowAddPicture(false),
            }}
            size={DialogSize.M}
          >
            <UpdateUserPhoto
              displayName={currentUserName}
              setShowModal={setShowAddPicture}
              setImageBase64={setPictureToAdd}
              imageBase64={pictureToAdd}
            ></UpdateUserPhoto>
          </BaseDialog>
        </div>
        {formDataToConfirm && (
          <ConfirmDialog
            title={"Attention!"}
            render={
              <Text>
                There are no roles added for this user. Would you like to save
                it anyway?
              </Text>
            }
            isLoading={false}
            onConfirm={() => submitForm(formDataToConfirm)}
            onCancel={() => setSelectedPivotKey("roles")}
            onClose={() => {
              setLoading(false);
              setFormDataToConfirm(null);
            }}
          />
        )}
      </BaseDialog>
      <BaseDialog
        hidden={!showUrlDialog.show}
        dialogContentProps={{
          type: DialogType.close,
          title: "Invite Url:",
          closeButtonAriaLabel: "Close",
          onDismiss: () => handleClose(),
        }}
        size={DialogSize.S}
      >
        <TextField
          readOnly
          value={showUrlDialog.url}
          size={100}
          onRenderInput={(props, defaultRenderer) => {
            return (
              <>
                {defaultRenderer(props)}
                <TooltipHost content={"Copy key"}>
                  {showUrlDialog.url ? onPass(props.value as string) : <></>}
                </TooltipHost>
              </>
            );
          }}
        />
      </BaseDialog>
    </>
  );
};
