import { Breadcrumb, IconButton, Stack, Text } from "@fluentui/react";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  commandBarStyles,
  pageStyle,
  titleStyle,
} from "../../../schema/Constants";
import {
  BaseCommandBar,
  Column,
  CommandBarItemProps,
  CommandBarItemType,
  Table,
  computeCommandBarItems,
  useTableFilters,
} from "web-analysis-lib";
import { useViewport } from "../../Layout/Content";
import {
  listAsyncSensorNodeDetails,
  selectSensorNodeDetails,
  selectSensorNodeDetailsStatus,
} from "./reducer";
import { useAuthorization } from "../../../Hooks/authorization";
import { ResponseSimplifiedSignal } from "../../Machines/models";
import { format } from "../../../schema/Utils";
import { selectProject } from "../../Projects/reducer";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";

const getColumns = (): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: "Data From",
      fieldName: "dataFrom",
      minWidth: 100,
      isSortable: true,
      onRender: ({ dataFrom }: ResponseSimplifiedSignal) =>
        dataFrom && format(new Date(dataFrom)),
    },
    {
      key: "dataUntil",
      name: "Data Until",
      fieldName: "dataUntil",
      minWidth: 100,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseSimplifiedSignal) =>
        dataUntil && format(new Date(dataUntil)),
    },
    {
      key: "sampleRateInHz",
      name: "Sample Rate In Hz",
      fieldName: "sampleRateInHz",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "unit",
      name: "unit",
      fieldName: "unit",
      minWidth: 50,
      isSortable: true,
    },
    {
      key: "configName",
      name: "Config Name",
      fieldName: "configName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorNo",
      name: "Sensor No",
      fieldName: "sensorNo",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "signalDescription",
      name: "Signal Description",
      fieldName: "signalDescription",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalGroup",
      name: "Signal Group",
      fieldName: "signalGroup",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalType",
      name: "Signal Type",
      fieldName: "signalType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "feature",
      name: "Feature",
      fieldName: "feature",
      minWidth: 150,
      isSortable: true,
    },
  ];

  return columns;
};

export const SensorNodeDetails: React.FunctionComponent = () => {
  const { id } = useParams();

  // const { bigScreen } = useViewport();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listAsyncSensorNodeDetails(id ? id : ""));
  }, [id, dispatch]);
  const navigate = useNavigate();
  const items = useAppSelector(selectSensorNodeDetails);
  const auth = useContext(authContext);
  const status = useAppSelector(selectSensorNodeDetailsStatus);
  const proj = useAppSelector(selectProject(items.wirelessGateway?.projectId));
  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.sensorNodeId}</Text>,
    },
    {
      key: "refresh",
      text: "Refresh",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Refresh" },
      onClick: () => {
        dispatch(listAsyncSensorNodeDetails(id ? id : ""));
      },
    },
  ];
  const { filters, handleSearch } = useTableFilters<ResponseSimplifiedSignal>({
    keys: [
      "name",
      "dataFrom",
      "dataUntil",
      "configName",
      "feature",
      "sensorNo",
      "signalDescription",
      "signalGroup",
      "signalType",
      "unit",
    ],
  });
  const goBack = () => navigate(-1);

  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            {items.wirelessGateway ? (
              <Breadcrumb
                items={[
                  {
                    key: `corporation-${proj?.corporation?.number}`,
                    text: proj?.corporation?.name,
                  },
                  {
                    key: `company-${proj?.company?.number}`,
                    text: proj?.company?.name,
                  },
                  { key: `project-${proj?.name}`, text: proj?.name },
                  {
                    key: `gateway-${items.wirelessGateway?.serialNumber}`,
                    text: items.wirelessGateway?.serialNumber,
                  },
                  {
                    key: `sensorNode-${items.sensorNodeId}`,
                    text: items.sensorNodeId,
                  },
                ]}
                styles={{
                  root: { margin: 0 },
                  item: { fontSize: "14px" },
                  chevron: { fontSize: "10px" },
                }}
              />
            ) : (
              <Breadcrumb
                items={[
                  {
                    key: `un-parented-sensorNode`,
                    text: "Un-parented sensorNode",
                  },
                ]}
                styles={{
                  root: { margin: 0 },
                  item: { fontSize: "14px" },
                  chevron: { fontSize: "10px" },
                }}
              />
            )}
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
              styles={commandBarStyles}
            />

            <Table
              persistOpts={{
                key: "table-Signals",
                version: 2,
              }}
              header={{
                title: "Signals",
              }}
              items={items.signals}
              columns={getColumns()}
              filters={filters}
              hasSelection={false}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />
          </div>
        </>
      ) : (
        <>
          <NotFoundRoute />
        </>
      )}
    </>
  );
};
