import { Home as Page } from "web-analysis-lib";
import { axiosInstance } from "../..";

import { useAppSelector } from "../../hooks";
import { selectUserSettings } from "../Settings/selectors";

export const Home: React.FunctionComponent = () => {
  const settings = useAppSelector(selectUserSettings);

  return (
    <>
      <Page axiosInstance={axiosInstance} settings={{...settings.dashboardSettings, gridItemsCount: settings?.list?.gridItemsCount}}/>
    </>
  );
};
