import { CommandBarButton, Stack } from "@fluentui/react";
import { CSSProperties } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { BrowserUtils, IPublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";

const buttonStyle: CSSProperties = {
  textAlign: "start",
  width: "100%",
  padding: "0.5em 0.2em 0.5em 0em",
};

export const UserMenu = ({
  handleDismiss,
}: {
  handleDismiss: (value) => void;
}) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] ? accounts[0] : {});

  async function signOutClickHandler(instance: IPublicClientApplication) {
    try {
      await instance.logoutRedirect({
        account: account,
      });
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack.Item>
          <CommandBarButton
            iconProps={{ iconName: "Settings" }}
            text="Settings"
            onClick={() => {
              navigate("settings");
              handleDismiss(false);
            }}
            style={buttonStyle}
          />
        </Stack.Item>
        <Stack.Item>
          <CommandBarButton
            iconProps={{ iconName: "SignOut" }}
            text="Sign out"
            onClick={() => signOutClickHandler(instance)}
            style={buttonStyle}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};
