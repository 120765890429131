/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import React from "react";
import { Layout } from "./Components/Layout/Layout";
import {
  Label,
  Spinner,
  SpinnerSize,
  Stack,
  ThemeProvider,
} from "@fluentui/react";
import { theme } from "web-analysis-lib";
import { ToastContainer } from "react-toastify";

export const App: React.FunctionComponent = () => {
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect
  );
  const { accounts } = useMsal();
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <>
          {accounts && (
            <ThemeProvider theme={theme}>
              <Layout />
              {/* <ToastContainer
                className="toast-container"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={true}
                closeOnClick={false}
                theme="light"
              /> */}
            </ThemeProvider>
          )}
        </>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Spinner size={SpinnerSize.large} />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};
