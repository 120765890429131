import { useMemo, useEffect, CSSProperties } from "react";
import {
  BaseDialog,
  useZodForm,
  renderFormItems,
  notification,
  DialogSize,
  FormItemProps,
  FormItemType,
} from "web-analysis-lib";
import {
  IDialogProps,
  DialogType,
  Stack,
  StackItem,
  Text,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { z } from "zod";
import { PendingUserExtendedProperties } from "./models";
import { useAppSelector } from "../../hooks";
import { Status } from "../../schema/status";
import type { FieldError } from "react-hook-form";
import { onPass, pageStyle, titleStyle } from "../../schema/Constants";
import {
  selectUsersPendingRBACError,
  selectUsersPendingRBACStatus,
} from "../UsersPendingRBAC/reducer";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

const userPendingFields: FormItemProps[] = [
  {
    name: "externalMemberId",
    type: FormItemType.TextField,
    groupProps: { label: "External Member Id" },
    style: { width: "450px" },
    readOnly: true,
    disabled: true,
    onRenderInput: (props, defaultRenderer) => {
      return (
        <>
          {defaultRenderer(props)} {onPass(props.value as string)}
        </>
      );
    },
  },
  {
    name: "email",
    type: FormItemType.TextField,
    groupProps: { label: "E-mail" },
    style: { width: "480px" },
    readOnly: true,
  },
  {
    name: "lastName",
    type: FormItemType.TextField,
    groupProps: { label: "Last Name" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "firstName",
    type: FormItemType.TextField,
    groupProps: { label: "First Name" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "jobTitle",
    type: FormItemType.TextField,
    groupProps: { label: "Job Title" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "companyName",
    type: FormItemType.TextField,
    groupProps: { label: "Company Name" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "streetAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Street" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "department",
    type: FormItemType.TextField,
    groupProps: { label: "Department" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "city",
    type: FormItemType.TextField,
    groupProps: { label: "City" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "stateOrProvince",
    type: FormItemType.TextField,
    groupProps: { label: "State" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "zipOrPostalCode",
    type: FormItemType.TextField,
    groupProps: { label: "Zip" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "countryOrRegion",
    type: FormItemType.TextField,
    groupProps: { label: "Country" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "mobilePhone",
    type: FormItemType.TextField,
    groupProps: { label: "Mobile" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "externalProvider",
    type: FormItemType.TextField,
    groupProps: { label: "External Provider" },
    style: { width: "150px" },
    readOnly: true,
  },
  {
    name: "creatorName",
    type: FormItemType.TextField,
    groupProps: { label: "Creator Name" },
    style: { width: "150px" },
    readOnly: true,
  },
];

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, { message: "This field is required" }),
    lastName: z.string().min(1, { message: "This field is required" }),
    firstName: z.string().min(1, { message: "This field is required" }),
    jobTitle: z.string().optional(),
    companyName: z.string().optional(),
    department: z.string().optional(),
    streetAddress: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z.string().optional(),
    zipOrPostalCode: z.string().optional(),
    countryOrRegion: z.string().optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: "Invalid number" }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    externalMemberId: z.string().optional(),
    displayName: z.string().optional(),
    initialPassword: z.string().optional(),
  });

type DetailsDialogProps = IDialogProps & {
  data?: PendingUserExtendedProperties;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialogPending = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectUsersPendingRBACStatus);
  const error = useAppSelector(selectUsersPendingRBACError);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const {
    //handleSubmit,
    //formState: { errors, isValid },
    formState: { errors },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        email: data.email ? data?.email : "",
        lastName: data?.lastName ? data?.lastName : "",
        firstName: data?.firstName ? data?.firstName : "",
        jobTitle: data?.jobTitle ? data?.jobTitle : "",
        companyName: data?.companyName ? data?.companyName : "",
        department: data?.department ? data?.department : "",
        streetAddress: data?.streetAddress ? data?.streetAddress : "",
        city: data?.city ? data?.city : "",
        stateOrProvince: data?.stateOrProvince ? data?.stateOrProvince : "",
        zipOrPostalCode: data?.zipOrPostalCode ? data?.zipOrPostalCode : "",
        countryOrRegion: data?.countryOrRegion ? data?.countryOrRegion : "",
        mobilePhone: data?.mobilePhone ? data?.mobilePhone : "",
        externalMemberId: data?.externalMemberId ? data?.externalMemberId : "",
        displayName: data?.displayName ? data?.displayName : "",
        initialPassword: data?.initialPassword ? data?.initialPassword : "",
      },
    }),
  });

  const handleClose = () => {
    onClose?.();
  };

  // const onSubmit = handleSubmit(async (formData) => {
  //   setLoading(true);

  //   const aux: UserUpdate = {
  //     ...(formData as UserExtendedProperties),
  //   };
  //   await updateUserRBAC(aux).then((response) =>
  //     onSuccess("status" in response, "")
  //   );

  //   handleClose();
  // });

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: "Details Pending User",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "62vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label="Pivots to display Pending User">
          <PivotItem
            headerText={"Properties"}
            itemKey={"properties"}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>Properties</Text>
            )}
          >
            <Stack horizontalAlign="baseline" verticalAlign="center">
              <div style={{ marginTop: "50px" }}>
                <Text style={titleStyle}>General</Text>
              </div>
              {/* <form onSubmit={onSubmit}> */}
              <Stack
                style={{ maxWidth: "70vw", padding: "35px" }}
                wrap
                horizontal
                tokens={{ childrenGap: 8 }}
                horizontalAlign="stretch"
                verticalAlign="center"
              >
                {renderFormItems(userPendingFields, {
                  control,
                  errors: errors as { [schemaProp: string]: FieldError },
                }).map((ele) => (
                  <StackItem key={ele.key}> {ele}</StackItem>
                ))}
              </Stack>
              {/* <DialogFooter>
                  <PrimaryButton
                    type="submit"
                    text="Save Changes"
                    disabled={isLoading || !isValid || !isFormChanged}
                    onRenderIcon={() =>
                      isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                    }
                  />
                </DialogFooter> */}
              {/* </form> */}
            </Stack>
          </PivotItem>
        </Pivot>
      </div>
    </BaseDialog>
  );
};
